import * as React from "react";
import { IconProps } from "..";

const Facebook = React.forwardRef<SVGSVGElement, IconProps>(
  ({ title = "facebook", titleId = "facebook", ...props }, svgRef) => {
    return (
      <svg
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        ref={svgRef}
        aria-labelledby={titleId}
        {...props}
      >
        {title ? <title id={titleId}>{title}</title> : null}
        <path
          d="M17.857 0H2.143A2.143 2.143 0 000 2.143v15.714A2.143 2.143 0 002.143 20H8.27v-6.8H5.458V10H8.27V7.56c0-2.774 1.652-4.306 4.182-4.306 1.211 0 2.478.216 2.478.216v2.723h-1.396c-1.375 0-1.804.853-1.804 1.729V10h3.07l-.49 3.2h-2.58V20h6.127A2.143 2.143 0 0020 17.857V2.143A2.143 2.143 0 0017.857 0z"
          fill="currentColor"
        />
      </svg>
    );
  }
);
export default Facebook;
